:root {
  --background-color: hsl(0, 0%, 96%);
  --editor-background: hsl(60, 100%, 100%);
  --error-color: hsl(0, 85%, 62%);
  --foreground-color: hsl(0, 0%, 0%);
  --primary-color: hsl(189, 100%, 63%);
  --shadow-color: hsla(0, 0%, 27%, 0.239);
  --warning-color: hsl(49, 100%, 40%);
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: hsl(0, 0%, 23%);
    --editor-background: hsl(0, 0%, 12%);
    --foreground-color: hsl(0, 0%, 100%);
    --shadow-color: hsl(0, 0%, 43%);
  }
}

body {
  background: var(--background-color);
  display: flex;
  flex-flow: column;
  font-family: sans-serif;
  height: 100vh;
  margin: 0;
}

h1 {
  margin: 0 1rem;
}

.navbar {
  align-items: center;
  background-color: var(--primary-color);
  display: flex;
  flex: 0 0 auto;
  height: 3rem;
  justify-content: space-between;
}

.nav-icon {
  text-decoration: none;
}

.nav-icon > img {
  height: 2rem;
  margin-right: 1rem;
  vertical-align: middle;
}

main {
  background: var(--editor-background);
  box-shadow: 0 0 10px var(--shadow-color);
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  margin: 1.5rem;
}

.tabs {
  background: var(--editor-background);
  display: flex;
  flex: 0 0;
  flex-flow: wrap;
  width: 100%;
}

.tabs > a,
.tabs > a:visited {
  background: transparent;
  box-shadow: inset 0 0 2px var(--shadow-color);
  color: var(--foreground-color);
  display: block;
  flex: 1 1 auto;
  padding: 1rem 1rem;
  text-align: center;
  text-decoration: none;
  transition: background 0.3s;
}

.tabs > button:hover,
.tabs > a:hover,
.tabs > a:target {
  background: var(--shadow-color);
}

.tabs > button {
  background: transparent;
  border: none;
  color: var(--foreground-color);
  cursor: pointer;
  padding: 0.5rem 2rem;
}

#editor {
  flex: 1 1 auto;
}

#problems,
#output {
  border-top: 1px solid var(--shadow-color);
  flex: 0 0 20vh;
  color: var(--foreground-color);
  margin: 0;
  overflow-y: scroll;
}

.problem {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 0.25rem;
}

.problem:hover {
  background-color: var(--shadow-color);
}

.problem-text {
  margin-left: 0.5rem;
}

.problem .codicon-warning {
  color: var(--warning-color);
}

.problem .codicon-error {
  color: var(--error-color);
}

*::-webkit-scrollbar {
  box-shadow: 1px 0 0 0 var(--scrollbar-color) inset;
  width: 14px;
}

*::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
}
